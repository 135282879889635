import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// HOC
import paginated from '../../../hoc/paginated';
import Meta from '../../shared/Meta';

// Components
import Loader from '../../shared/Loader';
// import Ad from '../shared/Ad';
import Sidebar from '../Sidebar';
import BlogPostsGrid from './BlogPostsGrid';

// Actions
import blogPostActions from '../../../actions/blogPostActions';
import { clearServerRenderedPath } from '../../../actions/uiActions';

// Selectors
import { getAllBlogPosts, getCityConfig } from '../../../selectors';

// Misc
import IsoMorphUtils from '../../../modules/isoMorphUtils';
import { getUrl } from '../../../modules/helpers';

import sidebarStyles from '../../../../stylesheets/public/layouts/SidebarLayout.module.postcss';

class BlogPosts extends Component {
  constructor(props, _railsContext) {
    super(props);
    this.state = {
      ready: false || IsoMorphUtils.ready(props)
    };

    this.url = getUrl();
    this.structuredData = {
      breadcrumbs: { listItems: [
        {
          "@type": "ListItem",
          "position": 2,
          "name": "Blog",
          "item": this.props.cityConfig.base_url + this.props.location.pathname,
        }
      ]}
    }
  }

  componentDidMount() {
    const { selectedPage } = this.props;
    if (IsoMorphUtils.serverRendered(this.props)) {
      this.props.clearServerRenderedPath();
    } else {
      this.fetchBlogPosts(selectedPage);
      this.setState({
        ready: true
      });
    }
  }

  componentWillUpdate(nextProps, nextState) {
    this.fetchBlogPostsIfWillUpdate(nextProps);
  }

  fetchBlogPostsIfWillUpdate(nextProps) {
    const currentPage = this.props.selectedPage;
    const nextPage = nextProps.selectedPage;

    if (currentPage !== nextPage) {
      this.fetchBlogPosts(nextPage);
    }
  }

  fetchBlogPosts(page) {
    this.props.fetchBlogPosts({ page, published: true, page_size: 15 });
  }

  render() {
    if (!this.state.ready) return null;

    const { blogPosts, pagination, getPagination, location, cityConfig } = this.props;

    return (
      <div className="PaddedSection">
        <Meta
          title={`The Blog - ${cityConfig.site_title}`}
          type="website"
          url={this.url}
          description={`Food blog covering ${cityConfig.city_name}'s best food ${cityConfig.vehicle_type.toLowerCase()}s, new food trends, and upcoming chefs in the food ${cityConfig.vehicle_type.toLowerCase()} world!`}
          structuredData={this.structuredData}
        />
        <div className={sidebarStyles.SidebarLayout}>
          <div className="Container">
            <div className={sidebarStyles.SidebarLayout_body}>
              <div className="u-mb2">
                <h1>Blog</h1>
              </div>

              <Loader entities="blog" actions="fetch" overlay overlayScreen>
                <BlogPostsGrid
                  blogPosts={blogPosts}
                  cityConfig={cityConfig}
                />
              </Loader>

              {pagination &&
                <div>{getPagination()}</div>
              }

              {/* <div className="Advertisement u-mt2 u-mb0">
                <Ad slot="7735243397" />
              </div> */}

            </div>
            <div className={sidebarStyles.SidebarLayout_sidebar}>
              <Sidebar marginTop />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

BlogPosts.propTypes = {
  blogPosts: PropTypes.array,
  clearBlogPosts: PropTypes.func,
  fetchBlogPosts: PropTypes.func,
  pagination: PropTypes.object,
  getPagination: PropTypes.func,
  resetSelectedPage: PropTypes.func,
  selectedPage: PropTypes.number,
  match: PropTypes.object,
  ui: PropTypes.object,
  location: PropTypes.object,
  clearServerRenderedPath: PropTypes.func,
  cityConfig: PropTypes.object,
};

function mapStateToProps(state, props) {
  return {
    blogPosts: getAllBlogPosts(state, props),
    ui: state.ui,
    cityConfig: getCityConfig(state)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    clearBlogPosts() {
      return dispatch(blogPostActions.clear());
    },
    fetchBlogPosts(options) {
      dispatch(blogPostActions.fetch(options));
    },
    clearServerRenderedPath() {
      dispatch(clearServerRenderedPath());
    }
  };
}

const paginationConfig = {
  entity: 'blog_post'
};

const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(BlogPosts);
const paginatedComponent = paginated(connectedComponent, paginationConfig);
export default paginatedComponent;
