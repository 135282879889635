import baseActionsFor from './baseActions';
import asyncActionsFor from './asyncActions';
import { blogPostSchema, blogPostListSchema } from './schemas';

// Create Base Actions
const baseActions = baseActionsFor('blog_posts', 'blogPost');

// Create Base Async Actions
const asyncActions = asyncActionsFor({
  baseActions,
  resourceName: 'blog_post',
  listName: 'blog_posts',
  apiEndpoint: 'blog_posts',
  resourceSchema: blogPostSchema,
  listSchema: blogPostListSchema
});

// Custom Actions and Base Action Overrides
const customActions = {};

// Merge Actions
const actions = Object.assign(baseActions, asyncActions, customActions);

// Export
export default actions;
