import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import { CommentCount } from 'disqus-react';

import IsoMorphUtils from '../../../modules/isoMorphUtils';

import styles from './Blog.module.postcss';
import tripleStyles from '../content/Triple.module.postcss';

class BlogPostTease extends Component {
  render() {
    const { blogPost, cityConfig } = this.props;

    const disqusConfig = {
      url: `${IsoMorphUtils.railsContext.href}/${blogPost.id}`,
      identifier: `sft-blog-${blogPost.id}`,
      title: blogPost.title,
    };

    const date = dayjs(blogPost.post_date).format('MMMM D, YYYY');

    let image = null;
    if (blogPost.photo) {
      image = `${IsoMorphUtils.railsContext.imageBase}/${blogPost.photo}`;
    }

    return (
      <Link className={tripleStyles.Triple + " " + styles.BlogPostTease} to={`/blog/${blogPost.id}`}>
        <img src={image} />
        <h6>{blogPost.title}</h6>
        <p>
          {`${date} / `}
          <CommentCount
            shortname={cityConfig.disqus_shortname}
            config={disqusConfig}
          >{'0 Comments'}</CommentCount>
        </p>
      </Link>
    );
  }
}

BlogPostTease.propTypes = {
  blogPost: PropTypes.object
};

BlogPostTease.defaultProps = {
  blogPost: {
    food_categories: [{ name: 'Food Category' }],
    name: 'BlogPost Name',
    id: '#'
  }
};

export default BlogPostTease;
