import React, { Component } from 'react';
import PropTypes from 'prop-types';

import BlogPostTease from './BlogPostTease';

import tripleStyles from '../content/Triple.module.postcss';

class BlogPostsGrid extends Component {
  render() {
    const { blogPosts, cityConfig } = this.props;

    let displayedBlogPosts = null;
    if (blogPosts) {
      displayedBlogPosts = blogPosts.map((blogPost) => {
        return (
          <BlogPostTease blogPost={blogPost} key={blogPost.id} cityConfig={cityConfig} />
        );
      });
    }

    return (
      <div className={tripleStyles.Triple_wrapper + " " + tripleStyles.Triple_wrapper__two}>
        {displayedBlogPosts}
      </div>
    );
  }
}

BlogPostsGrid.propTypes = {
  blogPosts: PropTypes.array
};

export default BlogPostsGrid;
